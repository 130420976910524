.pag4{
    background-image: url("./components/back.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    @media (min-width: 300px) and (max-width: 600px) {
        width: fit-content;
    }
    color: black;
}
.pag3{
    background-image: url("./components/back1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;

    color: black;
}

body {
    cursor: url('/src/components/logo.png'), auto;
}


.image {
    transition: transform 1s ease-out;
    transform: translateY(0);
}

.image.hidden {
    transform: translateY(100%);
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

.image {
    animation: slideInFromBottom 1s ease-out;
}

/* For Webkit-based browsers */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #e1caca; /* Color of the track */
}

::-webkit-scrollbar-thumb {
    background-color: #e37b31; /* Color of the scroll thumb */
    border-radius: 6px; /* Rounded corners */
    border: 3px solid rgba(211, 106, 20, 0.47); /* Border around the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker color on hover */
}


